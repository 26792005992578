import { validatorRequire, validatorRequireNumber, validatorEmail } from '@/utils/validators';
import MDinput from '@/components/MDinput';
import DateTimePicker from '@/components/DSE/DateTimePicker';
import Switch from '@/components/DSE/Switch';
import i18n from '@/i18n';

export const validatorEmploymentDate = (startDate, endDate) => (rule, value, callback) => {
  if (endDate && startDate > endDate) {
    callback(new Error(i18n.t('employee.employment_date_error')));
  } else {
    callback();
  }
};

export function getEmployeeFormDefinition(vue) {
  return {
    name: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 64,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    employmentStartDate: {
      type: DateTimePicker,
      defaultValue: null,
      props: {
        caption: 'employee.employment_start_date',
        type: 'date',
        format: vue.$store.getters.dateFormat
      },
      rules: [{ validator: validatorRequire }],
      dynamicRules: form => [
        {
          validator: validatorEmploymentDate(form.employmentStartDate, form.employmentEndDate)
        }
      ]
    },
    employmentEndDate: {
      type: DateTimePicker,
      defaultValue: null,
      props: {
        caption: 'employee.employment_end_date',
        type: 'date',
        format: vue.$store.getters.dateFormat
      },
      dynamicRules: form => [
        {
          validator: validatorEmploymentDate(form.employmentStartDate, form.employmentEndDate)
        }
      ]
    },
    residence: {
      type: MDinput,
      defaultValue: '',
      props: {
        caption: 'employee.residence',
        maxlength: 64,
        required: false
      }
    },
    phone: {
      type: MDinput,
      props: {
        caption: 'common.phoneIce',
        type: 'tel'
      }
    },
    mobilePhone: {
      type: MDinput,
      props: {
        type: 'tel',
        phoneNumberMaxLength: 20
      },
      rules: [{ validator: validatorRequire }]
    },
    email: {
      type: MDinput,
      props: {
        type: 'email',
        maxlength: 64
      },
      rules: [
        {
          validator: validatorEmail
        }
      ]
    },
    cost: {
      type: MDinput,
      defaultValue: null,
      props: {
        type: 'currency',
        caption: 'employee.cost',
        placeholderAlwaysVisible: true,
        min: 0,
        max: 100
      },
      rules: [{ validator: validatorRequireNumber }]
    },
    hourlyWage: {
      type: MDinput,
      defaultValue: null,
      props: {
        type: 'currency',
        caption: 'employee.hourly_wage',
        placeholderAlwaysVisible: true,
        min: 0,
        max: 100
      },
      rules: [{ validator: validatorRequireNumber }]
    },
    change: {
      type: MDinput,
      defaultValue: null,
      props: {
        type: 'currency',
        caption: 'employee.change',
        placeholderAlwaysVisible: true,
        min: 0,
        max: 100
      },
      rules: [{ validator: validatorRequireNumber }]
    },
    onlineYN: {
      type: Switch,
      defaultValue: false,
      props: {
        activeText: 'common.yes',
        inactiveText: 'common.no'
      }
    }
  };
}
